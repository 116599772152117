export function Logo(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="500.000000pt"
      height="500.000000pt"
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="401.000000pt"
        height="401.000000pt"
        viewBox="0 0 450.000000 450.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,401.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1930 3850 c-19 -5 -294 -83 -610 -173 -316 -90 -616 -174 -667 -187
-120 -29 -172 -62 -207 -131 l-26 -53 0 -1127 c0 -1105 0 -1128 20 -1193 16
-51 33 -79 76 -125 63 -69 89 -83 558 -306 169 -80 428 -205 575 -277 327
-160 332 -162 381 -155 35 4 282 119 835 388 105 50 258 124 340 163 83 40
174 84 203 100 104 53 177 152 202 271 7 34 10 419 8 1160 l-3 1110 -28 48
c-37 62 -95 99 -200 127 -113 29 -513 141 -739 206 -541 156 -628 175 -718
154z m175 -236 c28 -8 104 -30 170 -49 135 -37 874 -252 1005 -291 47 -14 91
-31 98 -36 8 -7 12 -39 12 -94 l0 -84 -1372 2 -1373 3 -3 76 c-3 88 3 98 70
123 24 8 219 65 433 126 215 62 485 140 600 175 225 68 277 75 360 49z m961
-789 c18 -13 -12 -45 -518 -551 l-537 -538 -513 518 c-282 286 -519 527 -526
538 -36 50 -80 48 1036 48 893 0 1041 -2 1058 -15z m-1722 -735 l168 -170 -27
-32 c-63 -76 -819 -838 -832 -838 -11 0 -13 151 -13 878 l1 877 267 -273 c147
-149 343 -349 436 -442z m2041 -1039 c-3 -3 -201 190 -440 429 l-435 435 438
443 437 444 3 -873 c1 -480 0 -875 -3 -878z m-453 122 c131 -133 238 -246 238
-250 0 -10 -155 -89 -500 -254 -135 -64 -334 -160 -443 -213 -110 -53 -203
-96 -208 -96 -11 0 -533 248 -906 430 -139 68 -253 128 -253 134 1 15 802 827
817 826 6 0 67 -54 135 -120 192 -187 211 -186 407 5 l126 124 174 -172 c96
-95 282 -281 413 -414z"
          />
          <path
            d="M2275 2629 c-11 -6 -82 -73 -158 -148 l-138 -136 -81 88 c-87 94
-119 117 -168 117 -37 0 -90 -47 -90 -80 0 -36 18 -58 165 -206 194 -196 162
-200 412 48 179 178 193 195 193 228 -1 71 -76 121 -135 89z"
          />
        </g>
      </svg>
    </svg>
  )
}
